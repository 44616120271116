import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Razor's Edge Barbershop
			</title>
			<meta name={"description"} content={"Від традиційних стрижок і гоління до сучасних укладок і стрижки бороди, наші перукарі володіють різними техніками, щоб гарантувати, що ви отримаєте бажаний вигляд. "} />
			<meta property={"og:title"} content={"Razor's Edge Barbershop"} />
			<meta property={"og:description"} content={"Від традиційних стрижок і гоління до сучасних укладок і стрижки бороди, наші перукарі володіють різними техніками, щоб гарантувати, що ви отримаєте бажаний вигляд. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header>
		<Section background="--color-dark" padding="80px 0 0 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline3"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Контактна інформація
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Ми запрошуємо вас відкрити різницю Razor's Edge. Наше прагнення до досконалості та пристрасть до перукарського мистецтва вирізняють нас. Завітайте до нас сьогодні та відчуйте впевненість, яка приходить, коли ви виглядаєте та почуваєтеся якнайкраще.
				</Text>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Pushkina St, 7, Khmelnytskyi,{" "}
					<br />
					Khmelnytskyi Oblast, 29000
					<br />
					<br />
					0679140056
					<br />
					<br />
					info@aurorainspired.com
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1">
				Pushkina St, 7, Khmelnytskyi,{" "}
				<br />
				Khmelnytskyi Oblast, 29000
				<br />
				<br />
				0679140056
				<br />
				<br />
				info@aurorainspired.com
			</Override>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});